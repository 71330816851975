<template>
    <div id="references">
        <!-- JUMBOTRON -->
        <div class="knowledge-base-jumbotron">
            <div class="knowledge-base-jumbotron-content lg:p-32 md:p-24 sm:p-16 py-8 rounded-lg mb-base">
                <h1 class="mb-1 text-white">Ceddan Bilişim Teknolojileri</h1>
                <h1 class="mb-1 text-white">En<strong> İyisiyle </strong> Çalıştığınızdan Emin Olmalısınız!</h1>
                <p class="text-white">Marketten Restorana,Zincir Mağazalardan Kobi İşletmelerine,İnşaat'tan Gıda'ya, Otomotiv'den Mobilya'ya, Demir-Çelik'ten Enerji'ye hemen hemen tüm üretim sektörlerinde yeralan şirketlerden, İthalat ve İhracat alanında sektörünün önünde yeralan şirketlerden, Perakende ve Toptan Alım-Satım alanında faaliyet gösteren tüm ticari kuruluşlara, Lojistik, Eğitim,Dershane,Kolej,Sağlık ve Turizm'den Müşavirlik Hizmetlerine kadar tüm hizmet sektöründe her ölçekteki Özel ve Kamu kuruluşlarına yönelik ürünleri ile kullanıcı odaklı özel çözümler sunuyoruz.</p>
								<h1 class="mb-1 text-white">Bazı Referanslarımız</h1>
                <vs-input placeholder="Arama Konusu veya Anahtar Kelime" v-model="knowledgeBaseSearchQuery" icon-pack="feather" icon="icon-search" size="large" class="w-full no-icon-border mt-6" />
            </div>
        </div>

        <!-- KNOWLEDGE BASE CARDS  -->
        <div class="vx-row" id="references" >
                <div class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base" v-for="item in filteredKB" :key="item.id" >
                    <vx-card class="text-center cursor-pointer" itemscope itemtype="https://schema.org/Article">
                        <a rel="nofollow" target="_blank" :href='item.url' ><img itemprop="image" :src="item.img" :alt="item.title" height="100" width="150" class="mx-auto mb-4"></a>
                        <h4 class="mb-2" itemprop="name">{{ item.title.toUpperCase() }}</h4>
                        <span itemprop="author" v-show="false">Ceddan</span>
                        <span itemprop="headline">{{item.title}}</span>
                        <small itemprop="description">{{ item.description }}</small>
                        <!-- <div itemprop="interactionStatistic" itemscope itemtype="https://schema.org/InteractionCounter">
                            <div itemprop="interactionService" itemscope itemid="https://www.ceddan.com" itemtype="https://schema.org/WebSite">
                            <meta itemprop="name" content="ceddan" />
                        </div>
                        </div> -->
                        <div itemprop="interactionStatistic" itemscope itemtype="https://schema.org/InteractionCounter">
                                <meta itemprop="interactionType" content="https://schema.org/CommentAction"/>
                                <meta itemprop="userInteractionCount" content="780" />
                        </div>
                    </vx-card>
                </div>
        </div>  

        <!-- <div itemscope itemtype="https://schema.org/Article">
            <span itemprop="name">How to Tie a Reef Knot</span>
            by <span itemprop="author">John Doe</span>
                This article has been tweeted 1203 times and contains 78 user comments.
            <div itemprop="interactionStatistic" itemscope itemtype="https://schema.org/InteractionCounter">
            <div itemprop="interactionService" itemscope itemid="http://www.twitter.com" itemtype="https://schema.org/WebSite">
                <meta itemprop="name" content="Twitter" />
            </div>
            <meta itemprop="interactionType" content="https://schema.org/ShareAction"/>
            <meta itemprop="userInteractionCount" content="1203" />
            </div>
            
</div> -->

    </div>
</template>

<script>

export default{
    data() {
        return {
            knowledgeBaseSearchQuery: '',
            kb: [
								{ id: 1, title: 'Gimsa Marketler Zinciri', description: 'Gimsa Marketler Zinciri', graphic: 'graphic-1.png' ,img: 'http://gimsa.com.tr/Resim/TR/SiteAyar/20140205135429-Logo.png',url: 'http://www.gimsa.com.tr'},
								{ id: 2, title: 'Ato Ankara Ticaret Odası', description: 'ATO Ankara Ticaret Odası', graphic: 'graphic-1.png' ,img: 'https://www.atonet.org.tr/Content/imgs/logo.png', url:'https://www.atonet.org.tr'},
								{ id: 3, title: 'Anadolu Medeniyetleri Müzesi', description: 'Anadolu Medeniyetleri Müzesi', graphic: 'graphic-1.png' ,img: 'https://muze.gov.tr/img/logo.png',url:'https://muze.gov.tr'},
								{ id: 4, title: 'Ankara Kültür Varlıklarını Koruma Müdürlüğü', description: 'Ankara Kültür Varlıklarını Koruma Müdürlüğü', graphic: 'graphic-1.png' ,img: 'https://kvmgm.ktb.gov.tr/images/ktb_logo_.png',url:'https://kvmgm.ktb.gov.tr'},
								{ id: 5, title: 'Ankamar', description: 'Ankamar Marketler', graphic: 'graphic-1.png' ,img: 'http://www.ankamar.com.tr/libs/img/logo-light.png',url:'http://www.ankamar.com.tr'},
								{ id: 6, title: 'Altınışık Marketler', description: 'Altınışık Marketler', graphic: 'graphic-1.png' ,img: 'https://www.altinisikonline.com/images/thumbs/0004118_alt%C4%B1n%C4%B1%C5%9F%C4%B1ksonlogo.jpeg',url:'https://www.altinisikonline.com'},
								{ id: 7, title: 'Özel Harekat', description: 'Özel Harekat Dairesi Başkanlığı', graphic: 'graphic-1.png' ,img: 'https://www.egm.gov.tr/kurumlar/egm.gov.tr/anasayfaTasarim/logo/DaireLogo/ozelharekat.png',url:'https://www.egm.gov.tr/ozelharekat'},
								{ id: 8, title: 'Elizin Pastaneleri', description: 'Elizin Pastaneleri', graphic: 'graphic-1.png' ,img: 'https://www.elizinn.com.tr/content/upfiles/elizinn-logo-1-tr.png',url:'https://www.elizinn.com.tr'},
								{ id: 9, title: 'Aspava Yıldızbeyi', description: 'Aspava Yıldızbeyi Döner Pide Kebap', graphic: 'graphic-1.png' ,img: 'https://instagram.fesb5-1.fna.fbcdn.net/v/t51.2885-19/s320x320/121080836_370519284132347_1017851410475526501_n.jpg?_nc_ht=instagram.fesb5-1.fna.fbcdn.net&_nc_ohc=jWab55LNwREAX_fCvWG&tp=1&oh=b96978cc28d7e862aeb690dadb0ebc25&oe=602FA906',url:'https://www.instagram.com/yildiz_beyi_aspava/?hl=tr'},
								{ id: 10, title: 'Öz Büro İş Sendikası', description: 'Öz Büro İş Sendikası', graphic: 'graphic-1.png' ,img: 'https://www.ozburois.org/tr/wp-content/uploads/2019/03/%C3%BCst-logo2-1.png',url:'https://www.ozburois.org'},
								{ id: 11, title: 'Gölbaşı Belediyesi', description: 'Gölbaşı Belediyesi', graphic: 'graphic-1.png' ,img: 'https://www.ankaragolbasi.bel.tr/assets/frontend/images/logo2.png',url:'https://www.ankaragolbasi.bel.tr'},
								{ id: 12, title: 'Cumhuriyet Lezzet Dünyası', description: 'Cumhuriyet Lezzet Dünyası', graphic: 'graphic-1.png' ,img: 'http://www.cld.com.tr/images/logo.png',url:'http://www.cld.com.tr'},
								{ id: 13, title: 'TBMM Destek Hizmetleri', description: 'TBMM Destek Hizmetleri', graphic: 'graphic-1.png' ,img: 'https://www.tbmm.gov.tr/favicon.ico',url:'https://www.tbmm.gov.tr/develop/owa/web_rehber.ekran?pKod=95'},
								{ id: 14, title: 'Fiskobirlik Efit', description: 'Fiskobirlik Efit', graphic: 'graphic-1.png' ,img: 'https://www.fiskobirlikefit.com/tr/images/logo.png',url:'https://www.fiskobirlikefit.com/tr/index.html'},
								{ id: 15, title: 'Akbak Otomotiv', description: 'Akbak Otomotiv', graphic: 'graphic-1.png' ,img: 'https://www.akbak.com.tr/public/images/akbak_logo.png',url:'https://www.akbak.com.tr'},
								{ id: 16, title: 'Leofarma', description: 'Leofarma Sağlık Ürünleri', graphic: 'graphic-1.png' ,img: 'https://www.hastane.com.tr/Images/Firm/Big/leofarma.png',url:'http://leofarma.com.tr/'},
								{ id: 17, title: 'Türkerler Astaldı', description: 'Türkerler Astaldı', graphic: 'graphic-1.png' ,img: 'https://i0.wp.com/bi-ozet.com/wp-content/uploads/2016/08/1470904421_t__rkerler_dikey.jpg?fit=940%2C506&ssl=1',url:'https://www.turkerler.com/'},
								{ id: 18, title: 'Çitlekçi', description: 'Çitlekçi Kuruyemiş TUNÇLAR A.Ş', graphic: 'graphic-1.png' ,img: 'https://citlekci.com.tr/Uploads/EditorUploads/logo2.png',url:'https://citlekci.com.tr/'},
								// { id: 19, title: 'Tümaş', description: 'Tümaş Türk Mühendislik Müşavirlik ve Mütahitlik A.Ş', graphic: 'graphic-1.png' ,img: 'https://www.tumas.com.tr/images/tumaslogo2.png',url:'https://www.tumas.com.tr'},
                                { id: 20, title: 'Ambalaj Gross', description: 'Ambalaj Gross Market', graphic: 'graphic-1.png' ,img: 'https://ambalajgross.com.tr/image/catalog/101/logo-11.jpg',url:'https://ambalajgross.com.tr/'},
                                { id: 21, title: 'Elmacı Market', description: 'Elmacı Market', graphic: 'graphic-1.png' ,img: 'http://elmacimarket.com/logo.jpg',url:'http://elmacimarket.com/'},
                                { id: 22, title: 'ÖZPA Market', description: 'ÖZPA Market', graphic: 'graphic-1.png' ,img: 'http://ozpamarket.com/logo.jpg',url:'http://ozpamarket.com/'},
								// { id: 21, title: 'Tulipack', description: 'TULİPACK, hem mevcut kapasitesini artırdı hem de margarin, dondurma, süt ürünleri, dondurulmuş gıda, catering, şekerleme ve çikolata başta olmak üzere geniş bir ürün yelpazesine sahip oldu. Yine 2014 yılında TULİPACK, yurt içindeki geniş müşteri portföyünün yanı sıra yurt dışında ki müşterilere de ulaşmayı başarıp, ihracat faaliyetlerine başladı.', graphic: 'graphic-1.png' ,img: 'http://www.tulipack.com.tr/tulipack/assets/img/tulipack-logo.png',url:'http://www.tulipack.com.tr'},
								// { id: 22, title: 'Birimza', description: 'Bir İmza Sanat Organizasyon, Geleneksel Türk Sanatları ve Modern Sanat Eserleri alanlarında, alanında öncü sanatçılar ile sergi organizasyonları , atölyeler, seminerler ve çeşitli projelerle faaliyetlerini sürdürmektedir.', graphic: 'graphic-1.png' ,img: 'http://www.birimza.com.tr/images/logo.png',url:'http://www.birimza.com.tr'},
								// { id: 23, title: 'Solena Enerji', description: 'Yenilenebilir enerji sektöründe faaliyet göstermek amacıyla 2013 yılında limited şirket olarak kurulan firmamız, "Enerji & Telekomünikasyon" sektöründe, kullanılan yöntem ve kaliteli ürünler ile en yüksek verimde en ucuz şekilde temiz enerji üretmektedir. Şirket politikamız', graphic: '' ,img: 'http://www.solenaenerji.com/images/solena-logo.svg?crc=362430736',url:'http://www.solenaenerji.com'},
								// { id: 24, title: 'Ceiba', description: 'CEIBA TELE-ICU teknolojinin tüm olanaklarını kullanarak ve kendi yazılımını geliştirerek yoğun bakım yönetiminde en ideal koşulları oluşturmakta kararlı olarak yola çıktı. Yoğun bakım ortamı kesintisiz olarak deneyimli ve yetkin uzmanlar tarafından izlenecektir. Bu amaçla, alanlarında kendini kanıtlamış profesyonellerden oluşan kadrosuyla yüksek teknolojik alt yapıyı kurdu. Yüksek kalite standartları kapsamında proses prosedürlerini belirledi.', graphic: '' ,img: 'http://www.ceibateleicu.com/assets/images/template/logo.png',url:'http://www.ceibateleicu.com'},
								// { id: 25, title: 'Eliz', description: 'Eliz Yapışkanlı Bant ve İzolasyon Malzemeleri, kurulduğu 1997 yılından bu yana müşteri odaklı hizmet anlayışı ile çalışmakta ve satışını sunduğu ürünlerle müşterilerinin ihtiyaçları doğrultusunda fiyat/kalite dengeli çözüm üretmektedir.', graphic: '' ,img: 'http://www.eliz.com.tr/img/logo.png',url:'http://www.eliz.com.tr'},
								// { id: 26, title: 'Eto Enerji', description: 'ETO Enerji A.Ş. alçak gerilim, orta gerilim ve zayıf akım elektrik tesisat işleri ve otomasyon konularında mühendislik, proje, taahhüt, teknik servis, bakım ve müşavirlik hizmetleri vermek üzere 2003 yılında kurulmuştur. 25’i aşkın sabit teknik kadrosuyla alanında hizmet vermektedir.', graphic: '' ,img: 'https://static.wixstatic.com/media/b5ee60_67c47bc1974b47cfac3c15584d882b8e~mv2.png/v1/fill/w_402,h_137,al_c,lg_1,q_85/7.webp',url:'http://www.etoenerji.com.tr'},
								


                
            ]
        }
    },
    computed: {
        filteredKB() { 
            return this.kb.filter((item) => item.title.toLowerCase().includes(this.knowledgeBaseSearchQuery.toLowerCase()) || item.description.toLowerCase().includes(this.knowledgeBaseSearchQuery.toLowerCase()));
        }
    },
    methods: {},
    components: {}
}
</script>

<style lang="scss">
.knowledge-base-jumbotron-content {
    background-image: url('../../assets/images/pages/knowledge-base-cover.jpg');
    background-size: cover;
}
</style>



